import { ApiRequestConfig } from '@typedefs/Api';
import { getAccountNumber } from '@utils/auth';
import { isProduction, isSecurity } from '@utils/environment';
import { AxiosPromise } from 'axios';
import { apiRequest, generateDataOrParams } from './apiConfig';

/*
 * Types
 */
type ApiBillingV1Props = ApiRequestConfig & {
  showEndpoint?: boolean;
  externalAccountNumber?: boolean;
};

interface ShowEndPointReturnType {
  endpoint: string;
  makeRequest: () => AxiosPromise;
}

type ApiBillingV1Type = (props: ApiBillingV1Props) => AxiosPromise | ShowEndPointReturnType;

const baseURL = isProduction
  ? 'https://app.marketing360.com/api/billing/account'
  : 'https://dev.billing.madwire.network/billing/account';

export const apiBilling: ApiBillingV1Type = ({
  url,
  method,
  payload,
  cache = false,
  showEndpoint = false,
  externalAccountNumber = false
}) => {
  const baseRequest = apiRequest(baseURL);
  const dataOrParams = generateDataOrParams(method);
  const config = {
    url: `${externalAccountNumber || getAccountNumber()}/${url}`,
    method,
    [dataOrParams]: payload,
    cache
  };
  if (showEndpoint) {
    return {
      endpoint: `${baseURL}/${baseRequest.getUri(config)}`,
      makeRequest: () => baseRequest(config)
    };
  }
  return baseRequest(config);
};

const getBillingApiV2Environment = () => {
  if (isProduction) return 'https://billing.marketing360.com';
  if (isSecurity) return 'https://billing.security.marketing360.com';
  return 'https://billing.stage.marketing360.com';
};

export const apiBillingV2 = ({ url, method, payload, headers }: ApiRequestConfig) => {
  const baseRequest = apiRequest(getBillingApiV2Environment());
  // The new billing API (so far) prefers data over params for DELETE requests, so pass false:
  const dataOrParams = generateDataOrParams(method, false);
  return baseRequest({
    url,
    method,
    headers,
    [dataOrParams]: payload
  });
};
