import React, { createContext, useContext, ReactNode } from 'react';
import useBillingAccountSWR from '@hooks/settings/profile/billing/useBillingAccountSWR';

type BillingAccountContextValue = ReturnType<typeof useBillingAccountSWR>;

const BillingAccountContext = createContext<BillingAccountContextValue>(
  {} as BillingAccountContextValue
);

export const useBillingAccount = () => useContext(BillingAccountContext);

interface BillingAccountContextProviderProps {
  children: ReactNode;
}

const BillingAccountContextProvider: React.FC<BillingAccountContextProviderProps> = ({
  children
}) => {
  const billingAccountSWRContext = useBillingAccountSWR();

  return (
    <BillingAccountContext.Provider value={billingAccountSWRContext}>
      {children}
    </BillingAccountContext.Provider>
  );
};

export default BillingAccountContextProvider;
