import { apiBillingV2 } from '@api/billing';
import { GlobalStateContext } from '@context/GlobalContextProvider';
import useFetchSWR from '@hooks/useFetchSWR';
import { useContext, useMemo } from 'react';

/**
 *
 * useBillingAccountSWR hook:
 * Note that this hook is not usually used directly: mostly, it's used
 * to fetch data and populate the context/BillingAccountContextProvider,
 * and then that provider exports a useBillingAccount hook for accessing the context.
 * This may be used directly at the top level of modals, though, since they
 * don't have access to the same contexts as the files that created those modals.
 *
 */
const useBillingAccountSWR = () => {
  const { account: accountData, accountSwitch } = useContext(GlobalStateContext);
  const accountNumber = accountData?.accountNumber;
  const loadingAccountData = accountSwitch?.inProgress || !accountData;

  const billingApiHeaders = {
    'Marketing360-Account': accountNumber
  };

  // current plan info
  const {
    data: planData,
    isLoading: loadingCurrentPlan,
    error: currentPlanError,
    mutate: mutateCurrentPlan
  } = useFetchSWR(
    ['/v1/account/plans/?optimize&include=archive,buyout', accountNumber],
    async url =>
      (
        await apiBillingV2({
          url,
          method: 'POST',
          headers: billingApiHeaders,
          payload: {
            expand: ['addons'],
            /**
             * In order to be able to display disabled products we need to use both
             * includeDisabled and includeUnavailable because some disabled products can be available
             * and some can be unavailable.
             */
            includeDisabled: true,
            includeUnavailable: true
          }
        })
      )?.data,
    { errorRetryCount: 2, errorRetryInterval: 1000 }
  );

  const isStripe = accountData?.billingType === 'stripe';
  const currentPlanData = planData?.plans?.[planData?.base?.index];
  const isTrial =
    isStripe && accountData?.statusId !== 3 && currentPlanData
      ? !('subscription' in currentPlanData)
      : accountData?.statusId === 8;
  const currentTerms = currentPlanData?.terms?.find(terms => terms.subscription);
  const currentAddons = currentTerms?.addons || [];
  const isUnderContract = currentPlanData?.subscription?.isUnderContract;
  const licenses = [];
  const programs = [];
  const credits = [];
  const fees = [];
  currentAddons.forEach(addon => {
    switch (addon.type) {
      case 3:
        credits.push(addon);
        break;
      case 2:
        programs.push(addon);
        break;
      case 1:
        licenses.push(addon);
        break;
      default:
        // type 0 is typically called fees, but if they're recurring,
        // we show them in UIs under programs until further notice/product decisions
        if (addon.recurring) {
          programs.push(addon);
        } else {
          // these non-recurring fees generally won't get used in v3front
          // so we won't return this from the hook, but they're gathered
          // here if we need them later, and just to keep things in parallel
          // with the equivalent hook in BackOffice
          fees.push(addon);
        }
        break;
    }
  });

  const {
    data: billingAccount,
    isLoading: isBillingAccountLoading,
    error: billingAccountError,
    mutate: mutateBillingAccount
  } = useFetchSWR(
    isStripe ? [`/v1/account?include=preview,tenant,discounts`, accountNumber] : null,
    async url =>
      (
        await apiBillingV2({
          url,
          method: 'get',
          headers: billingApiHeaders
        })
      )?.data
  );

  // the activeDiscount is the full billing system def object for a given discount,
  // as opposed to Stripe's Discount or just the discount id on billingAccount?.subscription?.discount
  const activeDiscount = billingAccount?.discounts?.find(
    d => d.id === billingAccount?.subscription?.discount
  );
  // a "universal discount" means that the discount is applied to the subscription as a whole;
  // the planIds and addonIds arrays will both be empty. When this is the case, if the discount is a fixed amount
  // we'll only want to show the discount price on the base subscription, which lets the discount amounts shown in the UI
  // add up correctly to match the invoice preview total
  const isUniversalDiscount =
    activeDiscount && activeDiscount.planIds.length === 0 && activeDiscount.addonIds.length === 0;

  const isPendingChange = Boolean(billingAccount?.subscription?.pendingChange);

  // last invoice
  const {
    data: lastInvoice,
    isLoading: isLastInvoiceLoading,
    error: lastInvoiceError
  } = useFetchSWR(
    isStripe ? ['/v1/stripe/invoices/search', accountNumber] : null,
    async url =>
      (
        await apiBillingV2({
          url,
          method: 'get',
          headers: billingApiHeaders,
          payload: {
            query: `metadata["accountNumber"]:"${accountNumber}"`,
            limit: 1
          }
        })
      )?.data?.data[0]
  );

  // Stripe subscription info
  const {
    data: subscriptionData,
    isLoading: loadingSubscription,
    error: subscriptionError,
    mutate: mutateSubscription
  } = useFetchSWR(
    currentPlanData?.subscription?.stripeSubscriptionId
      ? [
          '/v1/stripe/subscriptions?expand[]=latest_invoice',
          accountNumber,
          currentPlanData.subscription.stripeSubscriptionId
        ]
      : null,
    async url =>
      (
        await apiBillingV2({
          url,
          method: 'GET',
          headers: {
            'Marketing360-Account': accountNumber
          }
        })
      )?.data
  );

  // payment cards
  const {
    data: paymentCards,
    isLoading: loadingPaymentCards,
    error: paymentCardsError,
    mutate: mutatePaymentCards
  } = useFetchSWR(
    ['v1/stripe/payment_methods', accountNumber, 'card'],
    async url =>
      (
        await apiBillingV2({
          url,
          method: 'get',
          payload: {
            type: 'card',
            limit: 100
          },
          headers: billingApiHeaders
        })
      )?.data?.data
  );

  // ACH bank accounts (us_bank_account in Stripe)
  const {
    data: paymentBankAccounts,
    isLoading: loadingPaymentBankAccounts,
    error: paymentBankAccountsError,
    mutate: mutatePaymentBankAccounts
  } = useFetchSWR(
    ['v1/stripe/payment_methods', accountNumber, 'us_bank_account'],
    async url =>
      (
        await apiBillingV2({
          url,
          method: 'get',
          payload: {
            type: 'us_bank_account',
            limit: 100
          },
          headers: billingApiHeaders
        })
      )?.data?.data
  );

  // Stripe customer data
  const {
    data: stripeCustomerRecord,
    isLoading: loadingStripeCustomerRecord,
    error: stripeCustomerRecordError,
    mutate: mutateStripeCustomerRecord
  } = useFetchSWR(
    ['v1/stripe/customers?expand[]=invoice_settings.default_payment_method', accountNumber],
    async url =>
      (
        await apiBillingV2({
          url,
          method: 'get',
          headers: billingApiHeaders
        })
      )?.data
  );

  const loadingPaymentMethods = loadingPaymentCards || loadingPaymentBankAccounts;
  const paymentMethodsError = paymentCardsError || paymentBankAccountsError;
  const defaultPaymentMethodId = subscriptionData?.default_payment_method;
  const defaultPaymentMethod =
    paymentBankAccounts && paymentCards && defaultPaymentMethodId
      ? paymentBankAccounts.concat(paymentCards).find(p => p.id === defaultPaymentMethodId)
      : null;

  const paymentMethods = useMemo(() => {
    let pmArray = [];
    if (paymentCards && paymentCards.length) {
      pmArray = pmArray.concat(paymentCards);
    }
    if (paymentBankAccounts && paymentBankAccounts.length) {
      pmArray = pmArray.concat(paymentBankAccounts);
    }
    return pmArray;
  }, [paymentCards, paymentBankAccounts]);

  return {
    accountData,
    accountNumber,
    loadingAccountData,
    currentPlanData,
    loadingCurrentPlan,
    currentPlanError,
    mutateCurrentPlan,
    isTrial,
    isStripe,
    isPendingChange,
    currentTerms,
    currentAddons,
    isUnderContract,
    licenses,
    programs,
    credits,
    subscriptionData,
    loadingSubscription,
    subscriptionError,
    mutateSubscription,
    paymentCards,
    loadingPaymentCards,
    paymentCardsError,
    mutatePaymentCards,
    paymentBankAccounts,
    loadingPaymentBankAccounts,
    paymentBankAccountsError,
    mutatePaymentBankAccounts,
    paymentMethods,
    stripeCustomerRecord,
    loadingStripeCustomerRecord,
    stripeCustomerRecordError,
    mutateStripeCustomerRecord,
    defaultPaymentMethodId,
    defaultPaymentMethod,
    loadingPaymentMethods,
    paymentMethodsError,
    billingAccount,
    billingAccountError,
    isBillingAccountLoading,
    mutateBillingAccount,
    activeDiscount,
    isUniversalDiscount,
    lastInvoice,
    isLastInvoiceLoading,
    lastInvoiceError
  };
};

export default useBillingAccountSWR;
